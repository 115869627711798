import { post, post_form } from '../http'

// 列表
export const getCoursePackageList = (data) => {
    return post({ path: '/coursePackage/list', data })
}

export const editCoursePackage = (data) => {
    return post({ path: '/coursePackage/mod', data })
}

export const addCoursePackage = (data) => {
    return post({ path: '/coursePackage/add', data })
}

export const delCoursePackage = (data) => {
    return post_form({ path: '/coursePackage/del', data })
}

export const addCourse = (data, packageId) => {
    if (packageId) {
        return post({ path: `/course/add?packageId=${packageId}`, data })
    }
    return post({ path: '/course/add', data })
}

export const editCourse = (data) => {
    return post({ path: '/course/mod', data })
}
// 列表
export const getCourseList = (data) => {
    return post({ path: '/course/list', data })
}

// 删除
export const delCourse = (data) => {
    return post_form({ path: '/course/remove', data })
}

export const getCourseTeacher = (data) => {
    return post({ path: '/courseTeacher/list', data })
}
export const saveCourseTeacher = (data) => {
    return post({ path: '/courseTeacher/save', data })
}
export const delCourseTeacher = (data) => {
    return post_form({ path: '/courseTeacher/del', data })
}

export const getSignUpList = (data) => {
    return post({ path: '/coursePackageUser/list', data })
}

// 获取直播推送商品
export const getLiveProduct = (data) => {
    return post_form({ path: '/course/liveSelling', data })
}
// 推送商品
export const liveSellPush = (data) => {
    return post_form({ path: '/course/liveSellPush', data })
}
// 撤销推送商品
export const liveSellRollback = (data) => {
    return post_form({ path: '/course/liveSellRollback', data })
}
// 更新商品信息
export const liveSellRefresh = (data) => {
    return post_form({ path: '/course/liveSellRefresh', data })
}
// 直播消息推送
export const liveMessagePush = (data) => {
    return post({ path: '/live/sendBroadcast', data })
}

export const getSchedule = (data) => {
    return post({ path: '/course/schedule', data })
}

// 获取课程 直播间数据
export const getCourseLiveUrl = (data) => {
    return post_form({ path: '/course/getMTCourse', data })
}

// 直播间抽奖
export const liveLottery = (data) => {
    return post_form({ path: '/live/lottery', data })
}

export const getTalkLiveurl = (data) => {
    return post_form({ path: '/live/adminUrl', data })
}

export const getEvaluationList = (data) => {
    return post({ path: '/course/evaluations', data })
}

export const getPulicCoursePackageList = (data) => {
    return post({ path: '/coursePackage/listWithCourse', data })
}

// 伪直播模版
export const liveListTpl = () => {
    return post({ path: '/course/listTpl', data: {} })
}

export const addCoursePackageMapping = (data) => {
    return post({ path: '/coursePackageMapping/add', data })
}
export const getCoursePackageMappingList = (data) => {
    return post({ path: '/coursePackageMapping/list', data })
}
export const modCoursePackageMapping = (data) => {
    return post({ path: '/coursePackageMapping/mod', data })
}
export const delCoursePackageMapping = (data) => {
    return post_form({ path: '/coursePackageMapping/del', data })
}

export const getCourseExtraQuery = (data) => {
    return post({ path: '/course/extraQuery', data })
}

export const getCourseAuditionUrl = (data) => {
    return post({ path: '/course/auditionUrl', data })
}

export const saveCourseTpl = (data) => {
    return post({ path: '/course/tpl/save', data })
}
export const getCourseTplList = (data) => {
    return post({ path: '/course/tpl/list', data })
}
export const delCourseTpl = (data) => {
    return post_form({ path: '/course/tpl/del', data })
}

export const getAttendList = (data) => {
    return post({ path: '/courseUserAttend/list', data })
}

// 文章编辑
export const addPdArticle = (data) => {
    return post({ path: '/pdArticle/add', data })
}
export const modPdArticle = (data) => {
    return post({ path: '/pdArticle/mod', data })
}
export const getPdArticleList = (data) => {
    return post({ path: '/pdArticle/list', data })
}
export const delPdArticle = (data) => {
    return post_form({ path: '/pdArticle/del', data })
}
