<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item" style="width: 20%">
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item" style="width: 20%">
                    <span class="condition_content">
                        <el-date-picker
                            v-model="currentDay"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            align="right"
                            unlink-panels
                            :clearable="false"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="getScheduleApi"
                        />
                    </span>
                </div>
                <div class="condition_item" style="width: 20%">
                    <span class="condition_content">
                        <el-select
                            filterable
                            v-model="teacherId"
                            placeholder="请选择老师"
                            clearable
                            @change="getScheduleApi"
                        >
                            <el-option
                                v-for="item in teacherList"
                                :key="item.id"
                                :label="`${item.virtualFlag ? '[伪]' : ''}${item.name}`"
                                :style="item.virtualFlag ? 'color: #aaa' : ''"
                                :value="item.id"
                            />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item" style="width: 20%">
                    <span class="condition_content">
                        <el-select v-model="liveType" placeholder="请选择直播类型" clearable @change="getScheduleApi">
                            <el-option label="直播" value="0" />
                            <el-option label="伪直播" value="1" />
                            <el-option label="互动直播" value="2" />
                        </el-select>
                    </span>
                </div>
            </div>
        </div>
        <el-table
            class="table"
            :data="tableData"
            border
            :span-method="onSpanMethod"
            :row-class-name="tableRowClassName"
        >
            <el-table-column align="center" width="100" label="日期">
                <template slot-scope="scope">
                    <span :class="scope.row.isToday ? 'today' : ''">{{ scope.row.day }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="80" label="星期">
                <template slot-scope="scope">
                    <span :class="scope.row.isToday ? 'today' : ''">{{ scope.row.week }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="startText" width="120" label="直播时间" />
            <el-table-column align="center" prop="startText" width="80" label="直播类型">
                <template slot-scope="scope">
                    <span class="status1" v-if="scope.row.mirageType === 0">直播</span>
                    <span class="status2" v-else-if="scope.row.mirageType === 1">伪直播</span>
                    <span class="status1" v-else-if="scope.row.mirageType === 2">互动直播</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="90" label="讲师">
                <template slot-scope="scope">
                    <span
                        :class="{ virtual_flag: teacherMap[scope.row.teacherId].virtualFlag }"
                        v-if="scope.row.teacherId && teacherMap && teacherMap[scope.row.teacherId]"
                    >
                        {{ scope.row.teacher.name }}
                    </span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="80" label="课程ID">
                <template slot-scope="scope">
                    <div v-if="scope.row.id">{{ scope.row.id }}</div>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="liveId" width="100" label="欢拓课程ID" />
            <el-table-column header-align="center" prop="title" label="课程" />
        </el-table>
    </div>
</template>
<script>
import ProjectSelect from '@/views/components/ProjectSelect'
import { getSchedule, getCourseTeacher } from '@/js/api/courseApi.js'
import dayjs from 'dayjs'

export default {
    components: { ProjectSelect },
    data() {
        return {
            projectNo: '',
            currentDay: [dayjs().day(1).format('YYYY-MM-DD'), dayjs().day(7).format('YYYY-MM-DD')],
            teacherList: [],
            teacherMap: null,
            teacherId: '',
            packageType: '',
            liveType: '',

            tableData: [],
        }
    },
    created() {
        // 初始化日期
        this.getCourseTeacherApi()
    },
    methods: {
        onProjectNoChange(val) {
            this.projectNo = val
            this.currentDay = [dayjs().day(1).format('YYYY-MM-DD'), dayjs().day(7).format('YYYY-MM-DD')]
            this.teacherId = ''
            this.packageType = ''
            this.liveType = ''
            this.getScheduleApi()
        },

        tableRowClassName({ row }) {
            return row.rowClass
        },
        onSpanMethod({ row, columnIndex }) {
            if (columnIndex === 0) {
                return [row.spanRow, row.spanRow === 0 ? 0 : 1]
            }
        },
        getScheduleApi() {
            const params = { projectNo: this.projectNo, startTimes: this.currentDay }
            if (this.teacherId) {
                params.teacherIds = [this.teacherId]
            }
            getSchedule(params).then((res) => {
                const _weekList = ['日', '一', '二', '三', '四', '五', '六']

                const _dayNum = dayjs(this.currentDay[1]).diff(this.currentDay[0], 'days')
                const _dayList = []
                for (let index = 0; index <= _dayNum; index++) {
                    const _day = dayjs(this.currentDay[0]).add(index, 'days')
                    _dayList.push({
                        day: _day.format('YYYY-MM-DD'),
                        dayTitle: _day.format('MM-DD'),
                        week: `星期${_weekList[_day.day()]}`,
                        isToday: dayjs().format('YYYY-MM-DD') === _day.format('YYYY-MM-DD'),
                        timeStamp: _day.valueOf(),
                        emptyData: true,
                        startText: '-',
                    })
                }
                const _list = []
                const _hasCourseDayList = []
                res.data.forEach((item) => {
                    let _isShowCourse = true
                    let _packageType = ''
                    if (item.coursePackages) {
                        item.coursePackages.forEach((pg) => {
                            if (this.packageType && this.packageType !== pg.type) {
                                _isShowCourse = false
                            }
                        })
                        _packageType = item.coursePackages[0]['type']
                    }
                    if (_isShowCourse) {
                        if (!this.liveType || parseInt(this.liveType) === item.mirageType) {
                            item.packageType = _packageType

                            const _cDay = dayjs(item.startTime)
                            item.day = _cDay.format('YYYY-MM-DD')
                            item.dayTitle = _cDay.format('MM-DD')
                            item.week = `星期${_weekList[_cDay.day()]}`
                            item.isToday = dayjs().format('YYYY-MM-DD') === item.startTime.substr(0, 10)
                            item.timeStamp = _cDay.valueOf()
                            item.startText = `${item.startTime.substr(11, 5)} - ${item.endTime.substr(11, 5)}`
                            _list.push(item)
                            _hasCourseDayList.push(item.day)
                        }
                    }
                })
                _dayList.forEach((day) => {
                    if (!_hasCourseDayList.includes(day.day)) {
                        _list.push(day)
                    }
                })
                _list.sort((a, b) => a.timeStamp - b.timeStamp)

                // 合并单元格
                const _rowMap = {}
                _list.forEach((item) => {
                    if (!_rowMap[item.day]) {
                        _rowMap[item.day] = 0
                    }
                    _rowMap[item.day] += 1
                })
                _list.forEach((item) => {
                    item.spanRow = _rowMap[item.day]
                    _rowMap[item.day] = 0
                    _dayList.forEach((day, index) => {
                        if (day.day === item.day) {
                            item.rowClass = index % 2 ? '' : 'custom_row'
                        }
                    })
                })
                this.tableData = _list
            })
        },
        getCourseTeacherApi() {
            getCourseTeacher({}).then((res) => {
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.id] = item
                })
                this.teacherMap = _map
                this.teacherList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.today {
    font-weight: 700;
    color: #f00;
}
/deep/ .table {
    th .cell {
        font-size: 14px;
        color: #000;
        font-weight: 600;
    }
    td .cell {
        font-size: 13px;
        color: #333;
    }
    .virtual_flag {
        color: #999;
    }
    .custom_row {
        background: #f2f2f2;
    }
    .status1 {
        color: #ff0000;
    }
    .status2 {
        color: #999;
    }
}
</style>
